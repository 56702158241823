// Bootstrap
@import "~bootstrap/dist/css/bootstrap.min.css"
// Reset
@import "reset"
// Vars
@import "vars/colors", "vars/page-width"
// Mixins
@import "mixins/media", "mixins/adaptiv-font", "mixins/theme-mode"

html
  --color-brand: #188351
  --color-active: #188351

body
  font: 16px "Source Sans Pro", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif

a
  color: inherit
  text-decoration: unset
  transition: all .2s
  &:hover
    color: inherit
    opacity: .8

img
  max-width: 100%
  pointer-events: none

html, body, main
  height: 100%
main
  display: flex
  flex-direction: column

.block
  &#error
    flex-grow: 1
    display: flex
    align-items: center
    justify-content: center

// Components
@import "components"

@import "common"

.brand
  height: 190px
  transition: all .2s
  border: 1px solid #eee
  position: relative
  background-color: #fff
  &:hover
    transform: translateY(-10px)
    background-color: #fff
    box-shadow: #110c2e26 0px 48px 100px 0px
    opacity: 1
  img
    max-width: 150px
  &.active
    background-color: #eee

  mark
    background-color: var(--color-brand)
    width: 20px
    height: 20px
    position: absolute
    top: 10px
    left: 10px
    border-radius: 100%
    &:after
      content: ''
      position: absolute
      top: 0
      left: 0
      background: var(--color-brand)
      opacity: 0
      border-radius: 50%
      width: 100%
      height: 100%
      animation: sonar 2s 2s infinite

@font-face
  font-family: Oswald
  src: url('../fonts/Oswald.ttf') format("truetype")
  font-weight: 1 999
  font-display: swap

// @font-face
//   font-family: Roboto
//   src: url('../fonts/Roboto/Roboto-Medium.ttf') format("truetype")
//   font-weight: 1 999
//   font-display: auto

h1, h2, h3, h4, h5, h6
  font-family: Oswald
  text-transform: uppercase
  font-weight: 500

h1
  line-height: 1.1
  margin: 0
  @include adaptiv-font(45, 30)

h2
  line-height: 1.1
  margin: 0
  @include adaptiv-font(30, 25)

h3
  line-height: 1.1
  margin: 0
  @include adaptiv-font(25, 20)

h4
  line-height: 1.1
  margin: 0
  @include adaptiv-font(20, 20)

h5
  line-height: 1.1
  margin: 0
  @include adaptiv-font(20, 18)

p:not(:first-child)
  margin-top: 15px
  font-size: 18px

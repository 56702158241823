.blocks
  &.error
    margin: auto

.block
  padding: 90px 0
  background-color: $backGrey
  color: $textBlack
  position: relative
  @include for-size(max, phone-only)
    padding: 40px 0

  .cover
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    background-position: center center
    background-size: cover

.btn
  display: block
  font-family: Oswald, sans-serif
  text-transform: uppercase
  text-decoration: unset
  font-size: 16px
  max-width: 100%
  padding: 10px 20px
  background-color: var(--color-brand)
  color: white
  border-radius: 5px
  border: unset
  cursor: pointer
  box-shadow: 0 10px 20px rgba(#000, 0.3)
  transition: all 0.2s
  &:hover
    background-color: #146f45
    color: #fff
  &:disabled, &.disabled
    cursor: not-allowed
    background-color: $btnDisabledColor
    filter: saturate(0.3)

  &.full
    width: 100%

  &.flasher
    position: relative
    overflow: hidden
    &:after
      content: ""
      width: 50px
      background: #fff
      height: 200px
      top: -50px
      position: absolute
      transform: rotate(45deg)
      animation-duration: 5s
      animation-iteration-count: infinite
      animation-name: flasher

@keyframes flasher
  0%
    left: -100px
  20%
    left: 130%
  100%
    left: 130%
